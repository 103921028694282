import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";

if (environment.production) {
  enableProdMode();
}

function getEnvironmnet() {
  if (environment.development) {
    return 'development'
  }
  if (environment.staging) {
    return 'staging'
  }
  if (environment.production) {
    return 'production'
  }
}

if (getEnvironmnet() != 'development') {
  Sentry.init({
    dsn: "https://71fd4a98ec2eb2ea2f9f2c91ba483ceb@o4508594837520384.ingest.us.sentry.io/4508597311176704",
    environment: getEnvironmnet(),
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
